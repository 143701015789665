import React from 'react';
import { motion } from 'framer-motion';
import '../styles/About.css';

const About = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <section id="about" className="about">
      <div className="container">
        <motion.h2 
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ duration: 0.6 }}
        >
          About Cannon IT Solutions
        </motion.h2>
        <div className="about-content">
          <motion.div 
            className="about-text"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <h3>Innovating Since 2021</h3>
            <p>
              At Cannon IT Solutions, we're not just a tech company – we're your partners in digital transformation. 
              Founded in 2021, we've quickly risen to the forefront of the digital revolution, 
              combining cutting-edge technology with creative thinking to deliver solutions that drive business growth.
            </p>
            <h3>Our Mission</h3>
            <p>
              We're on a mission to empower businesses of all sizes with transformative digital solutions. 
              Our team of experts works tirelessly to bridge the gap between complex technology and practical business needs, 
              ensuring our clients stay ahead in the ever-evolving digital landscape.
            </p>
          </motion.div>
          <motion.div 
            className="about-stats"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <div className="stat-item">
              <span className="stat-number">50+</span>
              <span className="stat-label">Satisfied Clients</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">100+</span>
              <span className="stat-label">Projects Completed</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">24/7</span>
              <span className="stat-label">Support</span>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;